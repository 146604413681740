<template>
  <div id="app">
    <div>
      <h1>Developer Settings</h1>
      <p class="dev-settings">
        Current Value: <strong>Dev ID: </strong>
        {{ devID || '(no value set)' }}
        <strong>| Distribution Channel: </strong>
        {{ distributionChannel || '(no value set)' }}
      </p>
        <div class="input-option">
          <input v-model="devIDTextField" placeholder="Dev ID"/>
          <button class="cookie-submit" @click="setDevIDCookie(devIDTextField)">
            {{ devIDTextField ? 'Set' : 'Unset' }}
          </button>
        </div>
        <div class="input-option">
        <input v-model="distributionChannelTextField" placeholder="Distribution Channel"/>
        <button class="cookie-submit"
        @click="setDistributionChannelCookie(distributionChannelTextField)">
          {{ distributionChannelTextField ? 'Set' : 'Unset' }}
        </button>
        </div>

      <h2>Test Feature Flag</h2>
      <pre>testFlag = <code>{{ JSON.stringify(testFlag) }}</code></pre>

      <h2>User</h2>
      <pre>{{ JSON.stringify(user, null, 2) }}</pre>
    </div>
  </div>
</template>

<script>
import { user } from '@combinativ/client';
import featureFlags from './feature-flags';

function getCookieValue(cookieName) {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${cookieName}=`));
  const cookieValue = cookie && cookie.split('=')[1];
  return cookieValue ? decodeURIComponent(cookieValue) : cookieValue;
}

export default {
  name: 'App',
  components: {},

  data() {
    return {
      user: user.data,
      testFlag: featureFlags.getValue('testFlag'),
      devID: getCookieValue('devID'),
      distributionChannel: getCookieValue('distributionChannel'),
      devIDTextField: '',
      distributionChannelTextField: '',
    };
  },

  methods: {
    setCookie(name, value) {
      const cookieExpires = new Date();
      if (value) {
        cookieExpires.setTime(cookieExpires.getTime() + (1000 * 60 * 60 * 24 * 365)); // 1 Year
      } else {
        cookieExpires.setTime(0);
      }
      document.cookie = `${name}=${value}; expires=${cookieExpires.toUTCString()}; domain=manyvids.com`;
    },
    setDevIDCookie(value) {
      this.setCookie('devID', value);
      this.devID = value;
      this.devIDTextField = '';
    },
    setDistributionChannelCookie(value) {
      this.setCookie('distributionChannel', value);
      this.distributionChannel = value;
      this.distributionChannelTextField = '';
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  padding: 1.5%;
  margin-bottom: 1.5%;
  border-bottom: 1px solid #e0e0e0;
}

h2 {
  font-size: 1.2em;
  margin-top: 2em;
  margin-bottom: 0.2em;
}

input {
  height: 30px;
  background-color: #bbb;
  width: 300px;
  padding: 1%;
}

.cookie-submit {
  height: 30px;
  padding: 0 0.2em;
}

.input-option {
  padding-top: 0.5%;
}

pre {
  text-align: left;
  background: #222;
  color: #fff;
  border-radius: 1em;
  padding: 1em;
}

.dev-settings {
  margin-bottom: 0.5%;
}
</style>
